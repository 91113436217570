import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import isDesktop from '../../shared/utils/isDesktop';
import { generateTimeOptions } from '../../shared/utils/generateDeliveryTimeOptions';
import { getSelectedTime } from '../../shared/utils/deliveryOpeningHours';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '10px',
  },
  innerText: {
    ...theme.customFonts.medium,
  },
  closedText: {
    ...theme.customFonts.medium,
    color: '#5A7296',
  },
  gridItem: {
    borderBottom: `1px solid ${theme.sessionsScorePalette.nonCompliantLightGrey}`,
    padding: '8px 0',
  },
  lastItem: {
    borderBottom: 'none',
  },
}));

const WeekTimeRangeDay = (props) => {
  const {
    validTime: { day, isOpen, time, isOpen24Hours },
    className,
    lastItem,
  } = props;
  const classes = useStyles();
  const showOpeningAndClosingTime = isOpen && !isOpen24Hours;

  const firstLettersOfDay = (weekday) => (isDesktop ? weekday : weekday.slice(0, 2));

  const openingHour = parseInt(moment(time?.open).format('HH'), 10);
  const openingMinute = parseInt(moment(time?.open).format('mm'), 10);
  const closingHour = parseInt(moment(time?.close).format('HH'), 10);
  const closingMinute = parseInt(moment(time?.close).format('mm'), 10);

  const openTimeOptions = generateTimeOptions({
    currH: openingHour,
    currM: openingMinute,
  });

  const closeTimeOptions = generateTimeOptions({
    currH: closingHour,
    currM: closingMinute,
  });

  const selectedTime = {
    openSegment: getSelectedTime(openingHour, openingMinute, openTimeOptions),
    closeSegment: getSelectedTime(closingHour, closingMinute, closeTimeOptions),
  };

  return (
    <Grid container wrap="wrap" className={className}>
      <Grid item xs={3} className={`${classes.gridItem} ${lastItem ? classes.lastItem : ''}`}>
        <Typography className={classes.innerText} variant="body2">
          {firstLettersOfDay(day)}
        </Typography>
      </Grid>
      {showOpeningAndClosingTime ? (
        <Grid item xs={9} className={`${classes.gridItem} ${lastItem ? classes.lastItem : ''}`}>
          <Typography className={classes.innerText} variant="body2">
            {selectedTime.openSegment?.time} -{' '}
            {selectedTime.closeSegment?.time +
              (selectedTime.closeSegment?.isNextDay ? ' (next day)' : '')}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={9} className={`${classes.gridItem} ${lastItem ? classes.lastItem : ''}`}>
          {isOpen24Hours && (
            <Typography className={classes.innerText} variant="body2">
              Open 24 hours
            </Typography>
          )}
          {!isOpen && (
            <Typography className={classes.closedText} variant="body2">
              closed
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

WeekTimeRangeDay.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  validTime: PropTypes.object.isRequired,
  day: PropTypes.string,
  isOpen: PropTypes.bool,
  time: PropTypes.string,
  isOpen24Hours: PropTypes.bool,
  lastItem: PropTypes.bool,
};

WeekTimeRangeDay.defaultProps = {
  day: 'Unknown Day',
  isOpen: false,
  time: '',
  isOpen24Hours: false,
  lastItem: false,
};

export default WeekTimeRangeDay;
