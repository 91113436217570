import { formatTimeString } from './deliveryOpeningHours';

export const generateTimeOptions = ({
  currH,
  currM,
  prevH = 5,
  prevM = 0,
  show24HoursOption = false,
  endH = null,
  endM = null,
}) => {
  const timeOptions = [];

  if (show24HoursOption) {
    timeOptions.push({ time: 'Open 24 hours', isNextDay: false });
  }

  let h = prevH;
  let m = prevM;
  let firstIteration = true;
  while (firstIteration || h !== 5 || (h === 5 && m !== 0)) {
    let isNextDay = false;
    if ((h < 5 && h > 0) || (h === 0 && m >= 15)) {
      isNextDay = true;
    }
    if (currH !== null && currM !== null && h === currH && m === currM) {
      timeOptions.push({ time: formatTimeString(h, m), isNextDay });
    } else if (h !== null && m !== null && m % 15 === 0) {
      timeOptions.push({ time: formatTimeString(h, m), isNextDay });
    }

    if (endH !== null && endM !== null && h === endH && m === endM) {
      break;
    }

    m += 1;
    if (m >= 60) {
      m = 0;
      h = (h + 1) % 24;
    }
    firstIteration = false;
  }

  if (endH === null && endM === null) {
    timeOptions.push({ time: formatTimeString(4, 59), isNextDay: true });
  }
  return timeOptions;
};

export default generateTimeOptions;
