import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Paper, Grid, Typography, CardContent } from '@mui/material';
import { PedalBike } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

import withVenue from '../../../hoc/withVenue';
import Page from '../../../components/Page';
import BackArrow from '../../../components/BackArrow';
import {
  formatTimeString,
  parseOpeningHoursforUi,
} from '../../../shared/utils/deliveryOpeningHours';
import PageHeader from '../../../components/PageHeader';
import DeliveryHoursDialog from '../../../components/DeliveryHoursDialog';
import { updateVenue, fetchVenue } from '../../../store/venues';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import isDesktop from '../../../shared/utils/isDesktop';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  container: {
    padding: '16px',
    display: 'flex',
  },
  innerText: {
    ...theme.customFonts.mediumBold,
  },
  lightText: {
    color: '#5A7296',
    ...theme.customFonts.small,
  },
  openingHoursDay: {
    ...theme.customFonts.medium,
  },
  gridItem: {
    borderBottom: `1px solid ${theme.sessionsScorePalette.nonCompliantLightGrey}`,
    padding: '8px 0',
  },
  lastItem: {
    borderBottom: 'none',
  },
  closedText: {
    ...theme.customFonts.medium,
    color: '#5A7296',
  },
  editButton: {
    borderColor: theme.customPalette.grey,
    ...theme.customFonts.medium,
    borderRadius: 12,
  },
}));

const DeliveryOpeningHours = ({ venue, redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const classes = useStyles();
  const { deliveryOpenings } = venue;
  const [hoursAreUpdating, setHoursAreUpdating] = useState(false);
  const [deliveryOpeningHours, setDeliveryOpeningHours] = useState(deliveryOpenings);

  let formattedOpeningHours = parseOpeningHoursforUi({ openingHours: deliveryOpenings });

  const firstLettersOfDay = (weekday) => {
    const formattedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1).toLowerCase();
    return isDesktop ? formattedWeekday : formattedWeekday.slice(0, 2);
  };

  useEffect(() => {
    setDeliveryOpeningHours(deliveryOpenings);
  }, [deliveryOpenings]);

  if (!deliveryOpenings) {
    formattedOpeningHours = [];
  }

  const handleUpdateOpeningHours = async (newOpeningHours) => {
    setHoursAreUpdating(true);
    try {
      await dispatch(updateVenue({ deliveryOpenings: newOpeningHours }));
      await dispatch(fetchVenue());
      setShowEditDialog(false);
      showSuccessNotification('Delivery opening hours updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <Page>
      <PageHeader className={classes.pageHeader}>
        <Box>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography variant="h2">Delivery Opening Hours</Typography>
        </Box>
      </PageHeader>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid container className={classes.container}>
          <Grid item xs={11} sx={{ display: 'flex' }}>
            <PedalBike />
            <Box sx={{ pl: 1.5 }}>
              <Typography className={classes.innerText}>Delivery</Typography>
              <Typography className={classes.lightText}>Deliveroo, Uber Eats, Just Eat</Typography>
            </Box>
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              variant="outlined"
              className={classes.editButton}
              onClick={() => {
                setShowEditDialog(true);
                setHoursAreUpdating(false);
              }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>

        {!formattedOpeningHours ||
          (formattedOpeningHours?.length === 0 && (
            <CardContent>
              <Typography variant="h2">No delivery opening hours set</Typography>
            </CardContent>
          ))}

        {formattedOpeningHours && (
          <CardContent>
            <Grid container>
              {formattedOpeningHours.map(({ day, openingHours, closed }, index) => (
                <React.Fragment key={`${day}-edit-table`}>
                  <Grid container wrap="wrap">
                    <Grid
                      item
                      xs={3}
                      className={`${classes.gridItem} ${
                        index === formattedOpeningHours.length - 1 ? classes.lastItem : ''
                      }`}
                    >
                      <Typography variant="body2" className={classes.openingHoursDay}>
                        {firstLettersOfDay(day)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      className={`${classes.gridItem} ${
                        index === formattedOpeningHours.length - 1 ? classes.lastItem : ''
                      }`}
                    >
                      {closed ? (
                        <Typography className={classes.closedText} variant="body2">
                          closed
                        </Typography>
                      ) : (
                        openingHours.map(({ fromHour, fromMinute, toHour, toMinute, nextDay }) => {
                          const nextDayLabel =
                            nextDay && nextDay.charAt(0) + nextDay.toLowerCase().slice(1);
                          const formatFromTime = formatTimeString(fromHour, fromMinute);
                          const formatToTime = formatTimeString(toHour, toMinute);
                          return (
                            <Typography
                              variant="body2"
                              className={classes.openingHoursDay}
                              key={`${day}-${fromHour}-table`}
                            >
                              {formatFromTime} - {formatToTime}
                              {nextDay && ` (${nextDayLabel} morning)`}
                            </Typography>
                          );
                        })
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </CardContent>
        )}
      </Paper>
      <DeliveryHoursDialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        openingHours={deliveryOpeningHours}
        handleUpdateOpeningHours={handleUpdateOpeningHours}
        hoursAreUpdating={hoursAreUpdating}
      />
    </Page>
  );
};

export default withVenue(DeliveryOpeningHours, '/settings/venue');
