import React from 'react';
import { Typography, Button, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../../../CustomDialog';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'auto',
  },
  button: {
    borderRadius: '12px',
    marginLeft: '0 !important',
  },
}));

const DeliveryHoursDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title="There's no room for another shift"
      isDialogOpen={open}
      handleCloseDialog={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography>
          We cannot add any more shifts after 04:45am. Adjust your previous closing time to make
          room for another shift or change the times on the next day.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} className={classes.button}>
          OK
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default DeliveryHoursDialog;
