import React, { forwardRef } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdClose } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginTop: '3rem',
    '& .MuiPaper-root': {
      margin: 0,
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 2rem)',
        width: 'calc(100% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
      },
    },
  },
  dialogNoClose: {
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(5px)',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& h2': {
      ...theme?.customFonts?.largeBold,
    },
  },
  dialogTitle: {
    width: '100%',
    padding: '1rem',
  },
  image: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: '1rem',
  },
  imageCloseButton: {
    position: 'absolute',
    fontSize: '28px',
    top: '1rem',
    right: '1rem',
    backgroundColor: theme?.customPalette?.white,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
  },
  contents: {
    overflowY: 'auto',
    '& .MuiDialogActions-root': {
      padding: '1rem',
      gap: '1rem',
      borderTop: `1px solid ${theme?.customPalette?.greyLight}`,
      justifyContent: 'flex-start',
    },
  },
  actions: {
    padding: '1rem',
    gap: '1rem',
    borderTop: `1px solid ${theme?.customPalette?.greyLight}`,
    justifyContent: 'flex-start',
  },
}));

const CustomDialog = forwardRef(
  (
    { handleCloseDialog, isDialogOpen, title, children, imgUrl, onClick, actions, canClose = true },
    ref,
  ) => {
    const classes = useStyles();
    const closeCallback = canClose ? handleCloseDialog : () => {};
    const dialogClasses = `${classes.dialog} ${canClose ? '' : classes.dialogNoClose}`;

    return (
      <Dialog
        ref={ref}
        aria-labelledby="add-menu-section"
        open={isDialogOpen}
        fullWidth
        className={dialogClasses}
        onClose={closeCallback}
        onClick={onClick}
      >
        {!!imgUrl && canClose && (
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imgUrl} alt="Product" />
            <IconButton
              aria-label="close"
              onClick={closeCallback}
              className={classes.imageCloseButton}
              size="small"
            >
              <MdClose />
            </IconButton>
          </div>
        )}
        {title && (
          <div className={classes.title}>
            <DialogTitle
              id="add-menu-section"
              onClose={closeCallback}
              className={classes.dialogTitle}
            >
              {title}
            </DialogTitle>
            {!imgUrl && canClose && (
              <IconButton
                aria-label="close"
                onClick={closeCallback}
                className={classes.closeButton}
                size="small"
              >
                <MdClose />
              </IconButton>
            )}
          </div>
        )}
        <div className={classes.contents}>{children}</div>

        {actions && (
          <DialogActions disableSpacing className={classes.actions}>
            {actions}
          </DialogActions>
        )}
      </Dialog>
    );
  },
);

export default CustomDialog;
