import React, { useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Grid,
  Box,
  Autocomplete,
  TextField,
  IconButton,
  Button,
  Popover,
} from '@mui/material';
import { MdMoreHoriz } from 'react-icons/md';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { rem } from 'polished';
import { Field, useField } from 'formik';
import { Checkbox } from 'formik-mui';
import isDesktop from '../../../shared/utils/isDesktop';
import { generateTimeOptions } from '../../../shared/utils/generateDeliveryTimeOptions';
import { getSelectedTime } from '../../../shared/utils/deliveryOpeningHours';

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    borderRadius: 8,
    marginBottom: 8,
  },
  title: {
    fontSize: rem(16),
    fontWeight: 500,
    color: theme.palette.grey[800],
    cursor: 'pointer',
  },
  timeInput: {
    paddingRight: '4px',
  },
  lightText: {
    color: '#5A7296',
    ...theme.customFonts.medium,
  },
  dropdown: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    '& .MuiFormControl-root': {
      borderRadius: 8,
    },
    '& .MuiInputBase-input': {
      height: '7px',
    },
  },
  modalButton: {
    color: '#343860',
    justifyContent: 'start',
    ...theme.customFonts.medium,
  },
  formLabel: {
    alignItems: 'start',
    margin: 0,
    width: '100%',
  },
}));

const OpeningTimeField = (props) => {
  const { name, opening, setFieldValue, disabled = false, index, handleCopyHours } = props;
  const classes = useStyles();
  const [anchorEl, setPopoverEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [field] = useField(`${name}.isOpen`);
  const isChecked = field.value;

  const { open, close } = opening.time;

  const [previousOpenValue, setPreviousOpenValue] = useState(moment(open));
  const [previousCloseValue, setPreviousCloseValue] = useState(moment(close));

  const openingHour = parseInt(moment(open).format('HH'), 10);
  const openingMinute = parseInt(moment(open).format('mm'), 10);
  const closingHour = parseInt(moment(close).format('HH'), 10);
  const closingMinute = parseInt(moment(close).format('mm'), 10);

  const previousOpeningHour = parseInt(moment(open).add(15, 'minutes').format('HH'), 10);
  const previousOpeningMinute = parseInt(moment(open).add(15, 'minutes').format('mm'), 10);

  const openTimeOptions = generateTimeOptions({
    currH: openingHour,
    currM: openingMinute,
    prevH: undefined,
    prevM: undefined,
    show24HoursOption: true,
    endH: closingHour,
    endM: closingMinute,
  });

  const closeTimeOptions = generateTimeOptions({
    currH: closingHour,
    currM: closingMinute,
    prevH: previousOpeningHour,
    prevM: previousOpeningMinute,
  });

  const selectedTime = {
    openSegment: getSelectedTime(openingHour, openingMinute, openTimeOptions),
    closeSegment: getSelectedTime(closingHour, closingMinute, closeTimeOptions),
  };

  const handleFieldValueChange = ({ fieldName, value }) => {
    const fieldPath = `${name}.time.${fieldName}`;
    const openPath = `${name}.time.open`;
    const closePath = `${name}.time.close`;

    const wasOpen24Hours =
      previousOpenValue.format('HH:mm') === '00:00' &&
      previousCloseValue.format('HH:mm') === '00:00';

    if (value.time === 'Open 24 hours') {
      setFieldValue(openPath, moment('00:00', 'HH:mm'));
      setFieldValue(closePath, moment('00:00', 'HH:mm'));
      setPreviousOpenValue(moment('00:00', 'HH:mm'));
      setPreviousCloseValue(moment('00:00', 'HH:mm'));
    } else {
      setFieldValue(fieldPath, moment(value.time, 'HH:mm'));

      if (fieldName === 'open' && wasOpen24Hours) {
        const openTime = moment(value.time, 'HH:mm');
        const closeTime = openTime.clone().add(15, 'minutes');
        setFieldValue(closePath, closeTime);
      }

      if (fieldName === 'open') {
        setPreviousOpenValue(moment(value.time, 'HH:mm'));
      } else if (fieldName === 'close') {
        setPreviousCloseValue(moment(value.time, 'HH:mm'));
      }
    }
  };

  const handleOpenPopover = (event) => {
    setPopoverEl(event.currentTarget);
    setOpenPopover(!openPopover);
  };

  const renderAutocomplete = (timeOptions, value, fieldName, key) => (
    <Autocomplete
      options={timeOptions}
      getOptionLabel={(option) => option.time + (option.isNextDay ? ' (next day)' : '')}
      value={value}
      onChange={(_event, val) => {
        if (!val) return;
        handleFieldValueChange({
          fieldName,
          value: val,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          key={key}
          inputProps={{
            ...params.inputProps,
            readOnly: !isDesktop,
          }}
        />
      )}
      getOptionDisabled={(_option, optionIndex) => optionIndex === 1}
      fullWidth
      disabled={disabled}
      className={classes.dropdown}
      slotProps={{
        paper: {
          sx: {
            marginTop: 1,
            '& .MuiAutocomplete-listbox': {
              padding: '8px',
            },
          },
        },
      }}
      ListboxProps={{
        sx: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
          '& .MuiAutocomplete-option:hover': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
        },
      }}
    />
  );

  return (
    <Box mb={2} p={2} className={classes.timeWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Field
            name={`${name}.isOpen`}
            component={Checkbox}
            type="checkbox"
            sx={{
              padding: '0 8px 0 0',
              '& .MuiSvgIcon-root': { fontSize: 24, color: isChecked ? '#000000' : '#343860' },
            }}
            color="primary"
          />
          <Grid container sx={{ display: 'flex' }} justifyContent="space-between">
            <Typography
              onClick={() => {
                setFieldValue(`${name}.isOpen`, !isChecked);
              }}
              className={classes.title}
            >
              {opening.day}
            </Typography>
            <Typography className={classes.lightText}>{isChecked ? '' : 'closed'}</Typography>
          </Grid>
          {opening.isOpen && (
            <Grid>
              <IconButton
                onClick={(event) => handleOpenPopover(event)}
                size="small"
                disabled={disabled}
                id={`${index}-popover`}
              >
                <MdMoreHoriz color="#000000" />
              </IconButton>
              <Popover
                id={`${index}-popover`}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={() => {
                  setOpenPopover(false);
                  setPopoverEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box padding={1} display="flex" flexDirection="column">
                  <Button
                    className={classes.modalButton}
                    onClick={() => {
                      handleCopyHours(index);
                      setOpenPopover(false);
                      setPopoverEl(null);
                    }}
                  >
                    Copy hours to all days
                  </Button>
                </Box>
              </Popover>
            </Grid>
          )}
        </Grid>
        {opening.isOpen && (
          <Grid container sx={{ display: isDesktop ? 'flex' : 'grid', ml: '47px', mt: '16px' }}>
            <Grid item xs={12} md={6} className={classes.timeInput}>
              <FormControlLabel
                control={renderAutocomplete(
                  openTimeOptions.slice(0, openTimeOptions.length - 1),
                  selectedTime.openSegment,
                  'open',
                  'Open',
                )}
                label="Open"
                labelPlacement="top"
                className={classes.formLabel}
              />
            </Grid>
            {selectedTime.openSegment?.time !== 'Open 24 hours' && (
              <Grid item xs={12} md={6} className={classes.timeInput}>
                <FormControlLabel
                  control={renderAutocomplete(
                    closeTimeOptions,
                    selectedTime.closeSegment,
                    'close',
                    'Close',
                  )}
                  label="Close"
                  labelPlacement="top"
                  className={classes.formLabel}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

OpeningTimeField.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  opening: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default OpeningTimeField;
