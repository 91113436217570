import React from 'react';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import WeekTimeRangeSelector from '../../../components/WeekTimeRangeSelector/WeekTimeRangeSelector';
import withVenue from '../../../hoc/withVenue';
import Page from '../../../components/Page';
import BackArrow from '../../../components/BackArrow';
import PageHeader from '../../../components/PageHeader';
import { fetchVenue, updateVenue } from '../../../store/venues';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  openingHoursContainer: {
    '& .MuiDivider-root:last-of-type': {
      display: 'none',
    },
  },
  openingHoursDay: {
    padding: '8px 0',
    fontWeight: 'bold',
  },
  openingHours: {
    padding: '8px 0',
  },
}));

const OpeningSchema = Yup.object().shape({
  openings: Yup.array().of(Yup.string()).required('Please enter some opening hours'),
});

const OpeningHours = ({ venue, redirect }) => {
  const classes = useStyles();
  const { openings: openingTimes } = venue;
  const dispatch = useDispatch();
  const { showErrorNotification } = useNotifications();

  const handleOnSubmit = async (openings) => {
    try {
      await dispatch(updateVenue(openings));
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <Page>
      <PageHeader className={classes.pageHeader}>
        <Box>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography variant="h2">Venue opening hours</Typography>
        </Box>
      </PageHeader>
      <Formik
        initialValues={openingTimes}
        validationSchema={OpeningSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <WeekTimeRangeSelector
              onSubmit={(value) => {
                handleOnSubmit({ openings: value });
              }}
              label="Opening hours"
              name="openings"
              subjectText="Venue opening hours"
              validTimes={openingTimes}
              errors={errors}
              value={values.openings}
              showVenueOpeningHoursText
            />
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default withVenue(OpeningHours, '/settings/venue');
