import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Link } from 'react-router-dom';
import { Paper, Grid, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Storefront } from '@mui/icons-material';

import { parseGooglePlaceOpenings } from '../../shared/utils/googlePlaces';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import EditWeekTimeRangeDialog from './EditWeekTimeRangeDialog/EditWeekTimeRangeDialog';
import WeekTimeRangeDay from './WeekTimeRangeDay';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px',
    display: 'flex',
  },
  bottomContainer: {
    padding: '16px',
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    borderRadius: '0 0 16px 16px',
  },
  innerText: {
    ...theme.customFonts.mediumBold,
  },
  linkText: {
    ...theme.customFonts.medium,
    color: theme.customPalette.black,
  },
  lightText: {
    color: '#5A7296',
    ...theme.customFonts.small,
  },
  editButton: {
    borderColor: theme.customPalette.grey,
    ...theme.customFonts.medium,
    borderRadius: 12,
  },
}));

const WeekTimeRangeSelector = ({
  validTimes: passedValidTimes,
  onSubmit,
  subjectText,
  showVenueOpeningHoursText = false,
}) => {
  const classes = useStyles();
  const [validTimes, setValidTimes] = useState(passedValidTimes);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [parsedValidTimes, setParsedValidTimes] = useState(
    parseGooglePlaceOpenings(passedValidTimes),
  );
  const hasValidTimes = !isEmpty(validTimes);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const finalSubjectText = subjectText || 'Valid times';

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const editWeekTimeRange = async (editedOpenings) => {
    try {
      closeEditDialog();
      setValidTimes(editedOpenings);
      setParsedValidTimes(parseGooglePlaceOpenings(editedOpenings));
      onSubmit(editedOpenings);
      showSuccessNotification(`Successfully updated ${finalSubjectText}`);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  return (
    <>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid container className={classes.container}>
          <Grid item xs={11} sx={{ display: 'flex' }}>
            {showVenueOpeningHoursText && (
              <>
                <Storefront />
                <Grid sx={{ pl: 1.5 }}>
                  <Typography className={classes.innerText}>Venue</Typography>
                  <Typography className={classes.lightText}>Epos, QR menu & Kiosk</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              variant="outlined"
              className={classes.editButton}
              onClick={openEditDialog}
            >
              Edit
            </Button>
          </Grid>
        </Grid>

        {!hasValidTimes && (
          <CardContent>
            {finalSubjectText} have not been found. Please add them manually
          </CardContent>
        )}
        {hasValidTimes && (
          <CardContent>
            <Grid container>
              {parsedValidTimes.map((validTime, parsedIndex) => (
                <WeekTimeRangeDay
                  validTime={validTime}
                  key={validTime.day}
                  lastItem={parsedIndex === parsedValidTimes.length - 1}
                />
              ))}
            </Grid>
          </CardContent>
        )}

        {showVenueOpeningHoursText && (
          <Grid className={classes.bottomContainer}>
            <Link className={classes.linkText} to="/settings/venue/delivery-opening-hours">
              Set delivery opening times
            </Link>
            <Typography className={classes.lightText}>Deliveroo, Uber Eats, Just Eat</Typography>
          </Grid>
        )}

        <EditWeekTimeRangeDialog
          open={isEditDialogOpen}
          onClose={closeEditDialog}
          onEditWeekTimeRange={editWeekTimeRange}
          validTimes={validTimes}
          subjectText={subjectText}
        />
      </Paper>
    </>
  );
};

WeekTimeRangeSelector.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  validTimes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default WeekTimeRangeSelector;
