/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import moment from 'moment';
import { rem } from 'polished';
import { Field, useField } from 'formik';
import { Grid, Typography, Popover, Box, Button, IconButton } from '@mui/material';
import { Checkbox } from 'formik-mui';
import { MdMoreHoriz } from 'react-icons/md';
import { makeStyles } from '@mui/styles';
import TimeSegmentControl from './TimeSegmentControl';
import TooManyShiftsDialog from './TooManyShiftsDialog/TooManyShiftsDialog';

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    borderRadius: 8,
    marginBottom: 8,
  },
  modalButton: {
    color: '#343860',
    justifyContent: 'start',
    ...theme.customFonts.medium,
  },
  title: {
    fontSize: rem(16),
    fontWeight: 500,
    color: theme.palette.grey[800],
    cursor: 'pointer',
  },
}));

const DeliveryHoursDay = ({ index, setFieldValue, values, handleCopyHours, hoursAreUpdating }) => {
  const classes = useStyles();
  const [anchorEl, setPopoverEl] = useState(null);
  const [isTooManyShiftsDialogOpen, setIsTooManyShiftsDialogOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [field] = useField(`[${index}].closed`);
  const isChecked = !field.value;
  const { day, openingHours, closed } = values;

  const disabled = closed || hoursAreUpdating;

  const lastSegment = openingHours.length > 0 ? openingHours[openingHours.length - 1] : null;

  const firstLettersOfDay = (weekday) => {
    const formattedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1).toLowerCase();
    return formattedWeekday;
  };

  const showModal =
    (lastSegment?.toMinute === 45 || lastSegment?.toMinute === 59) &&
    lastSegment?.toHour === 4 &&
    lastSegment?.nextDay !== false;

  const handleNewSegment = () => {
    const fromTime = lastSegment
      ? moment({ hour: lastSegment.toHour, minute: lastSegment.toMinute }).add(15, 'minutes')
      : moment({ hour: 5, minute: 0 });

    const toTime = moment(fromTime).add(15, 'minutes');

    setFieldValue(`[${index}].openingHours`, [
      ...openingHours,
      {
        fromHour: fromTime.hours(),
        fromMinute: fromTime.minutes(),
        toHour: toTime.hours(),
        toMinute: toTime.minutes(),
        nextDay: toTime.isAfter(moment({ hour: 23, minute: 59 })),
      },
    ]);
  };

  const handleDayOpenClose = () => {
    setFieldValue(`[${index}].closed`, !closed);
    if (closed) {
      setFieldValue(`[${index}].openingHours`, [
        { fromHour: 5, fromMinute: 0, toHour: 23, toMinute: 0, nextDay: false },
      ]);
    } else {
      setFieldValue(`[${index}].openingHours`, []);
    }
  };

  const handleRemoveSegment = (segmentIndex) => {
    const newOpeningHours = openingHours.filter((_segment, i) => i !== segmentIndex);
    setFieldValue(`[${index}].openingHours`, newOpeningHours);
  };

  const handleOpenPopover = (event) => {
    setPopoverEl(event.currentTarget);
    setOpenPopover(!openPopover);
  };

  const closeTooManyShiftsDialog = () => {
    setIsTooManyShiftsDialogOpen(false);
  };

  return (
    <Box mb={2} p={2} className={classes.timeWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Field
            name={`[${index}].closed`}
            component={Checkbox}
            type="checkbox"
            checked={isChecked}
            sx={{
              padding: '0 8px 0 0',
              '& .MuiSvgIcon-root': { fontSize: 24, color: isChecked ? '#000000' : '#343860' },
            }}
            color="primary"
            disabled={hoursAreUpdating}
            onChange={handleDayOpenClose}
          />
          <Grid container sx={{ display: 'flex' }} justifyContent="space-between">
            <Typography
              onClick={() => {
                setFieldValue(`[${index}].closed`, !isChecked);
                handleDayOpenClose();
              }}
              className={classes.title}
            >
              {firstLettersOfDay(day)}
            </Typography>
            <Typography className={classes.lightText}>{isChecked ? '' : 'closed'}</Typography>
          </Grid>
          {isChecked && (
            <Grid>
              <IconButton
                onClick={(event) => handleOpenPopover(event)}
                size="small"
                disabled={disabled}
                id={`${index}-popover`}
              >
                <MdMoreHoriz color="#000000" />
              </IconButton>
              <Popover
                id={`${index}-popover`}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={() => {
                  setOpenPopover(false);
                  setPopoverEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box padding={1} display="flex" flexDirection="column">
                  {openingHours.length < 10 && (
                    <Button
                      className={classes.modalButton}
                      onClick={() => {
                        if (showModal) {
                          setIsTooManyShiftsDialogOpen(true);
                        } else {
                          handleNewSegment();
                          setOpenPopover(false);
                          setPopoverEl(null);
                        }
                      }}
                    >
                      Add another shift
                    </Button>
                  )}
                  <Button
                    className={classes.modalButton}
                    onClick={() => {
                      handleCopyHours(index);
                      setOpenPopover(false);
                      setPopoverEl(null);
                    }}
                  >
                    Copy hours to all days
                  </Button>
                </Box>
              </Popover>
            </Grid>
          )}
        </Grid>
        {isChecked && (
          <>
            {openingHours.map((openingSegment, segmentIndex) => (
              <TimeSegmentControl
                key={`${day}-${segmentIndex}`}
                previousSegment={openingHours[segmentIndex - 1] || null}
                segmentHours={openingSegment}
                segmentIndex={segmentIndex}
                dayIndex={index}
                setFieldValue={setFieldValue}
                disabled={disabled}
                handleNewSegment={() => handleNewSegment()}
                handleRemoveSegment={() => handleRemoveSegment(segmentIndex)}
                openingHours={openingHours}
              />
            ))}
          </>
        )}
      </Grid>
      <TooManyShiftsDialog open={isTooManyShiftsDialogOpen} onClose={closeTooManyShiftsDialog} />
    </Box>
  );
};

export default DeliveryHoursDay;
