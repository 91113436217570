import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, DialogActions, DialogContent } from '@mui/material';
import { Form as FormikForm, FieldArray, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { fromOpeningTimesToFormValues, toOpeningTimesFromValues } from './mappers';
import OpeningTimeField from './OpeningTimeField';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import OpeningTimesSchema from '../OpeningTimesSchema';
import CustomDialog from '../../CustomDialog';
import { LOWERCASE_WEEKDAYS } from '../../../shared/utils/deliveryOpeningHours';

const useStyles = makeStyles(() => ({
  dialogContent: {
    height: '400px',
    overflow: 'auto',
  },
  button: {
    borderRadius: '12px',
    marginLeft: '0 !important',
  },
}));

const EditWeekTimeRangeDialog = ({
  open,
  onClose,
  onEditWeekTimeRange,
  validTimes,
  subjectText = '',
}) => {
  const { showErrorNotification } = useNotifications();
  const classes = useStyles();
  const formref = useRef();
  const finalSubjectText = subjectText || 'Edit valid times';

  const initialValues = useMemo(() => {
    const init = fromOpeningTimesToFormValues(validTimes);
    return init;
  }, [validTimes]);

  const onSubmit = (values) => {
    try {
      const localOpenings = toOpeningTimesFromValues(values);
      onEditWeekTimeRange(localOpenings);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleCopyHours = (copyDayIndex) => {
    const { openings } = formref.current.values;
    const { isOpen, isOpen24Hours, time } = openings[copyDayIndex];

    const newFormattedOpeningHours = openings.map((day, index) =>
      index === copyDayIndex
        ? day
        : {
            day: LOWERCASE_WEEKDAYS[index],
            isOpen,
            isOpen24Hours,
            time,
          },
    );

    formref.current.setValues({ openings: newFormattedOpeningHours });
  };

  return (
    <CustomDialog title={`${finalSubjectText}`} isDialogOpen={open} handleCloseDialog={onClose}>
      <Typography sx={{ p: '0 1rem', color: '#5A7296' }}>
        Menu hours start from 5:00am and end at 4:59am.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={OpeningTimesSchema}
        validateOnMount
        onSubmit={onSubmit}
        innerRef={formref}
      >
        {({ isValid, setFieldValue, values }) => (
          <FormikForm noValidate>
            <DialogContent className={classes.dialogContent}>
              <FieldArray
                name="openings"
                render={() =>
                  values.openings.map((opening, index) => (
                    <OpeningTimeField
                      key={`${opening.day}`}
                      name={`openings.${index}`}
                      opening={opening}
                      setFieldValue={setFieldValue}
                      index={index}
                      handleCopyHours={handleCopyHours}
                    />
                  ))
                }
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose} className={classes.button}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid}
                data-test-id="save-opening-hours"
                className={classes.button}
              >
                Save
              </Button>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </CustomDialog>
  );
};

EditWeekTimeRangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditWeekTimeRange: PropTypes.func.isRequired,
  validTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default EditWeekTimeRangeDialog;
