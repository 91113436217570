import React, { useRef } from 'react';
import { Typography, Button, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, FieldArray } from 'formik';
import DeliveryHoursDay from './DeliveryHoursDay';
import {
  WEEKDAYS,
  parseOpeningHoursforApi,
  parseOpeningHoursforUi,
} from '../../shared/utils/deliveryOpeningHours';
import CustomDialog from '../CustomDialog';

function getNextDay(day) {
  const dayIndex = WEEKDAYS.indexOf(day);
  return WEEKDAYS[(dayIndex + 1) % 7];
}

const useStyles = makeStyles(() => ({
  dialogContent: {
    height: '400px',
    overflow: 'auto',
  },
  button: {
    borderRadius: '12px',
    marginLeft: '0 !important',
  },
}));

const DeliveryHoursDialog = ({
  open,
  onClose,
  openingHours,
  handleUpdateOpeningHours,
  hoursAreUpdating,
}) => {
  const classes = useStyles();
  const formikRef = useRef();
  const formattedOpeningHours = parseOpeningHoursforUi({ openingHours, addEmptyArray: true });

  const handleCopyHours = (copyDayIndex) => {
    const currentOpeningHours = formikRef.current.values;
    const dayToCopy = currentOpeningHours[copyDayIndex];

    const newFormattedOpeningHours = currentOpeningHours.map((day, index) => {
      if (index === copyDayIndex) return day;

      const newDay = {
        day: WEEKDAYS[index],
        openingHours: dayToCopy.openingHours,
        closed: dayToCopy.closed,
      };

      newDay.openingHours = newDay.openingHours.map((segment) => {
        let newSegment = segment;
        if (newSegment.nextDay) {
          newSegment = { ...newSegment, nextDay: getNextDay(day.day) };
        }
        return newSegment;
      });

      return newDay;
    });

    formikRef.current.setValues(newFormattedOpeningHours);
  };

  return (
    <CustomDialog title="Delivery opening hours" isDialogOpen={open} handleCloseDialog={onClose}>
      <Typography sx={{ p: '0 1rem', color: '#5A7296' }}>
        Menu hours start from 5:00am and end at 4:59am.
      </Typography>
      <Formik
        innerRef={formikRef}
        initialValues={formattedOpeningHours}
        onSubmit={(values) => {
          handleUpdateOpeningHours(parseOpeningHoursforApi(values));
        }}
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <DialogContent className={classes.dialogContent}>
              <FieldArray name="daysOfWeek">
                {() =>
                  values.map((value, index) => (
                    <React.Fragment key={value.day}>
                      <DeliveryHoursDay
                        index={index}
                        values={value}
                        setFieldValue={setFieldValue}
                        handleCopyHours={handleCopyHours}
                        hoursAreUpdating={hoursAreUpdating}
                      />
                    </React.Fragment>
                  ))
                }
              </FieldArray>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose} className={classes.button}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || hoursAreUpdating}
                className={classes.button}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default DeliveryHoursDialog;
