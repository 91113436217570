import React from 'react';
import { List } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import SettingsListItem from '../../../components/SettingsListItem';
import VenueSettingsForm from '../../../components/VenueSettingsForm';
import useEndpoint from '../../../hooks/useEndpoint';
import { getServiceStatusState } from '../../../store/serviceStatus/selectors';
import { fetchServiceStatus } from '../../../store/serviceStatus';
import StatusLabel from '../../../components/StatusLabel';
import Page from '../../../components/Page';
import { isDeliveryOnly, isMultivendor } from '../../../store/venues/selectors';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { isAdmin } = useRoles();
  const { data } = useEndpoint(getServiceStatusState, fetchServiceStatus());
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const typeMultivendor = useSelector(isMultivendor);
  const showVenueSettingsForm = isAdmin() || (!isAdmin() && !typeDeliveryOnly);
  const { foodDelay, drinksDelay } = data || {};

  const checkIfDelay = () => {
    if (foodDelay > 0 && drinksDelay > 0) {
      return 'foodAndDrinksDelay';
    }
    if (foodDelay > 0 && drinksDelay === 0) {
      return 'foodDelay';
    }
    if (drinksDelay > 0 && foodDelay === 0) {
      return 'drinksDelay';
    }
    return null;
  };

  return (
    <Page>
      <List className={classes.list}>
        <SettingsListItem
          title="Venue details"
          subtitle="Phone, Email, URL's"
          to="/settings/venue/venue-details"
          divider
          typeDeliveryOnly={typeDeliveryOnly}
          typeMultivendor={typeMultivendor}
        />
        <SettingsListItem
          title="Venue opening hours"
          subtitle="Epos, QR menu & Kiosk"
          to="/settings/venue/opening-hours"
          divider
        />
        <SettingsListItem
          title="Delivery opening hours"
          subtitle="Delivery apps"
          to="/settings/venue/delivery-opening-hours"
          divider
        />
        <SettingsListItem
          title="Service status"
          subtitle="Set a delay notification for orders"
          to="/settings/venue/service-status"
          warningLabel
          divider
          typeDeliveryOnly={typeDeliveryOnly}
        >
          {checkIfDelay() && (
            <div>
              <StatusLabel backgroundColor status={checkIfDelay()} />
            </div>
          )}
        </SettingsListItem>
        <SettingsListItem
          title="Vouchers"
          subtitle="Manage vouchers for your venue"
          to="/settings/venue/voucher-settings"
          divider
          typeDeliveryOnly
        />
        <SettingsListItem
          title="Upsells"
          subtitle="Manage upsells for Epos &amp; QR menu"
          to="/settings/venue/upsells"
          divider
          typeDeliveryOnly={typeDeliveryOnly}
          typeMultivendor={typeMultivendor}
        />
        <SettingsListItem
          title="Service charge"
          subtitle="Manage service charge for your venue"
          to="/settings/venue/service-charge"
          divider
          typeDeliveryOnly={typeDeliveryOnly}
        />
        <SettingsListItem
          title="Additional charge"
          subtitle="Include a custom additional charge for your venue"
          to="/settings/venue/additional-charge"
          divider
          typeDeliveryOnly={typeDeliveryOnly}
        />
        <SettingsListItem
          title="Courses"
          subtitle="Deliver food in separate courses such as Starters, Mains and Desserts"
          to="/settings/venue/courses"
          divider
          typeDeliveryOnly={typeDeliveryOnly}
          typeMultivendor={typeMultivendor}
        />
        {showVenueSettingsForm && <VenueSettingsForm />}
      </List>
    </Page>
  );
};

export default Settings;
